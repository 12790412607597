.App {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  text-align: center;
  padding: 10px;
}

.container {
  max-width: 800px;
  width: 100%;
  border-radius: 10px;
  box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.15);
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
}

.logo {
  height: 50px; /* Adjust as needed */
}

.title {
  margin-bottom: 20px;
}

.wallet-address {
  font-size: 20px;
}

.wallet-address span {
  word-wrap: break-word;
  display: inline-block;
  max-width: 90%;
}

.buttons {
  display: flex;
  gap: 10px;
  margin-top: 20px;
}

.buttons button {
  padding: 10px;
  border: none;
  color: white;
  cursor: pointer;
  background: linear-gradient(270deg, #683f99 0%, #ba2e93 100%);
  border-radius: 5px;
}

.buttons button:hover {
  opacity: 0.9;
}
